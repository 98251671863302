import * as FileSaver from 'file-saver'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as XLSX from 'xlsx'
import { filterShipmentsBySearchKey } from '../firebase/query/shipments'
import { timestampToLocaleString } from '../utils/utils'
import { Button } from './button'
import { Dialog, DialogActions, DialogBody, DialogTitle } from './dialog'
import { Field, Label } from './fieldset'
import { Input } from './input'
import { orgId } from '../../containers/Shipments/Details/model'

interface ShipmentExportModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

interface ShipmentsExport {
  searchKey: 'containerId' | 'hblNumber'
  searchValue: string
}

export const ShipmentExportModal = ({ isOpen, setIsOpen }: ShipmentExportModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ShipmentsExport>({
    defaultValues: {
      searchKey: 'containerId',
    },
  })

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToCSV = (d: any, containerId?: string) => {
    const rows: any = []
    d?.forEach((_d: any) => {
      _d?.data?.ocean_containers?.map((container: any) => {
        rows.push({
          Vessel: _d?.data?.vessel_name || '',
          Voyage: _d?.data?.vessel_voyage_number || '',
          'Departure date': timestampToLocaleString(_d?.data?.estimated_time_of_departure?.seconds) || '',
          'HBL#': _d?.data?.house_bill_of_lading_number || '',
          'AMS HBL': _d?.data?.automated_manifest_system_number || '',
          'Inner Piece Count': _d?.data?.total_packages?.value || '',
          'Inner Piece UOM': _d?.data?.total_packages?.unit || '',
          'Outer Piece Count': _d?.data?.total_pallets?.value || '',
          Volume: _d?.data?.total_volume?.value || '',
          Weight: _d?.data?.total_weight?.value || '',
          Incoterm: _d?.data?.incoterm || '',
          'Cargo description': container.items[0]?.item_description || '',
          'DG (Y/N)': '',
          POL: _d?.data?.port_of_loading?.unloc || '',
          POD: _d?.data?.port_of_discharge?.unloc || '',
          CFS: _d?.data?.place_of_delivery || '',
          'Delivery Location': '',
          Shipper: _d?.data?.shipper?.name || '',
          Consignee: _d?.data?.consignee?.name || '',
          Notify: _d?.data?.notify_party?.name || '',
          'Notification emails': _d?.data?.notify_party?.emails?.[0] || '',
          ETA: timestampToLocaleString(_d?.data?.estimated_time_of_arrival?.seconds) || '',
          Carrier: _d?.data?.ocean_carrier || '',
          'Cntr#': container?.container_number || '',
          'Carrier BL#': _d?.data?.master_bill_of_lading_number || '',
          'Equip. Size': container?.container_type || '',
          'Release Type': _d?.data?.house_bill_of_lading_release_type || '',
          'Original BOL': _d?.data?.number_of_origin_bill_of_lading || '',
          'IT Number': _d?.data?.cbp_entry_number || '',
        })
      })
    })

    const ws = XLSX.utils.json_to_sheet(rows)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, containerId + fileExtension)
  }

  const handleExport = async (data: ShipmentsExport) => {
    const d = await filterShipmentsBySearchKey({
      orgId,
      exportKeyType: data.searchKey,
      exportKeyValue: data.searchValue,
    })
    exportToCSV(d, data.searchValue)
  }

  return (
    <Dialog open={isOpen} onClose={setIsOpen} size="xl">
      <form onSubmit={handleSubmit(handleExport)}>
        <DialogTitle className="border-b py-3">Download the data</DialogTitle>
        <DialogBody className="border-b text-sm/6 text-zinc-900 dark:text-white">
          <div className="overflow-x-auto pb-3 px-4">
            <Field className="mb-4">
              <Label>Export Key</Label>
              <select
                {...register('searchKey')}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="containerId">Container #</option>
                <option value="hblNumber">HBL Number</option>
              </select>

            </Field>
            <Field className="">
              <Label>Value</Label>
              <Input
                type="text"
                className="w-[70%]"
                {...register('searchValue', {
                  required: 'Value is required',
                })}
                invalid={!!errors.searchValue}
              />
              {errors.searchValue && <Label className="!text-red-500">{errors.searchValue?.message}</Label>}
            </Field>
          </div>
        </DialogBody>
        <DialogActions className="!mt-5">
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button type="submit">Download</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
