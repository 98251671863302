import React from 'react'
import Select from 'react-select'
import { Button } from './button'
import { Fieldset, Label } from './fieldset'
import { Input } from './input'
import { Radio, RadioField, RadioGroup } from './radio'

export const InputFilter = ({ filterKey, config, data, setData }: any) => {
  const handleOnChange = (e: any, key: string) => {
    const colFilter = data[filterKey]
    setData((data: any) => ({
      ...data,
      [filterKey]: {
        ...colFilter,
        [key]: e,
      },
    }))
  }

  const renderInput = (type: string) => {
    let element = null
    switch (type) {
      case 'textbox':
        element = (
          <Input
            type="text"
            className="!my-2"
            value={data[filterKey].value}
            onChange={(e) => handleOnChange(e.target.value, 'value')}
          />
        )
        break
      case 'dropdown':
        element = (
          <Select
            isMulti
            options={config?.options}
            className="multi-select my-2"
            value={data[filterKey].value}
            onChange={(e) => handleOnChange(e, 'value')}
          />
        )
      default:
        break
    }
    return element
  }

  const handleDiscard = () => {
    const colFilter = data[filterKey]
    setData((data: any) => ({
      ...data,
      [filterKey]: {
        ...colFilter,
        value: typeof data[filterKey]?.value === 'string' ? '' : null,
        operation: '',
      },
    }))
  }

  return (
    <Fieldset>
      <div className="flex items-center justify-between">
        <div>
          <RadioGroup
            name={`${filterKey}_operation`}
            value={data[filterKey].operation}
            className="!mt-0 flex justify-start gap-x-4"
            onChange={(e) => {
              handleOnChange(e, 'operation')
            }}
          >
            {config?.operations?.map(
              ({ value, label, isDisabled = false }: { value: string; label: string; isDisabled: boolean }) => (
                <RadioField className="!mt-0">
                  <Radio value={value} disabled={isDisabled} />
                  <Label>{label}</Label>
                </RadioField>
              )
            )}
          </RadioGroup>
        </div>
        {data[filterKey].operation || data[filterKey].value ? (
          <div className="flex justify-end">
            <div className="!mt-0">
              <Button className="h-[30px]" color="red1" onClick={handleDiscard}>
                Discard
              </Button>
            </div>
          </div>
        ) : null}
      </div>

      {renderInput(config.element)}
    </Fieldset>
  )
}
