import { PaginatedQueryRequest } from '../firebase/model'

export function timestampToLocaleString(secs: number) {
  if (!secs) return ''
  var t = new Date(1970, 0, 1)
  t.setSeconds(secs)
  return t.toLocaleString()
}

export const createPaginateQuery = (filter: any, payload: PaginatedQueryRequest) => {
  const req = { ...payload }
  if (req.pageToken) {
    delete req.pageToken
  }
  const filters = []
  for (const column in filter) {
    if (Object.prototype.hasOwnProperty.call(filter, column)) {
      const col = filter[column]
      if (col.value && col.operation) {
        let val = col.value
        if (typeof val == 'object') {
          val = val?.map((_: any) => _.value)
        }
        const obj = {
          ...col,
          value: val,
        }
        filters.push(obj)
      }
    }
  }
  req.filter = filters
  req['sort'] = {
    key: 'created_at',
    value: 'desc',
  }
  return req
}
