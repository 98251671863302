import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ActionCards, ActionCardType } from '../../libs/components/action-cards'
import { Button } from '../../libs/components/button'
import DataTable from '../../libs/components/data-table'
import DocumentStatus from '../../libs/components/document-status'
import { InputFilter } from '../../libs/components/InputFilter'
import { disabledStatus, shipmentStats } from '../../libs/components/models/documentTypeMap'
import { PageTitle } from '../../libs/components/page-title'
import { ShipmentExportModal } from '../../libs/components/shipment-export-modal'
import Status from '../../libs/components/status'
import { PaginatedQueryRequest } from '../../libs/firebase/model'
import useShipments from '../../libs/hooks/useShipments'
import { createPaginateQuery } from '../../libs/utils'
import { orgId } from './Details/model'
import { dropdownOperations, inputboxOperations, modeOptions, validationStatusOptions } from './model'

export default function Shipments() {
  const [fetchShipmentsRequest, setFetchShipmentsRequest] = useState<PaginatedQueryRequest>({
    pageSize: 10,
    filter: [],
    sort: {
      key: 'created_at',
      value: 'desc',
    },
    orgId,
  })
  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const [cardData, setCardData] = useState<ActionCardType>(shipmentStats)
  const [filter, setFilter] = useState<any>({})
  const shipments = useSelector((state: any) => state.shipmentReducer)

  useShipments(fetchShipmentsRequest)

  useEffect(() => {
    const updatedCards = [...cardData]
    const {
      stats: { needsAttention, missingDocuments, inProgress, completed },
    } = shipments
    updatedCards[0].value = needsAttention
    updatedCards[1].value = missingDocuments
    updatedCards[2].value = inProgress
    updatedCards[3].value = completed
    setCardData([...updatedCards])
  }, [shipments.stats])

  const handleRedirectShipmentDetails = (shipmentId: string, isOpen: boolean) => {
    window.open(`${window.location.href}/${shipmentId || ''}?summary-view=true`, '_blank')
    // navigate(`/shipments/${shipmentId || ''}`, { state: { isSummaryExpanded: isOpen } })
  }

  const getDocumentStatus = (row: any, docType: any) => {
    const data = row.shipping_documents.find((r: any) => r.type === docType)
    return data?.processing_result_status_code || ''
  }

  const [columns, setColumns] = useState([
    {
      accessorKey: 'client_shipment_id',
      cell: (info: any) =>
        (
          <Link to={`/shipments/${info.row.original?.id}`} target="_blank" className="text-blue-400 underline">
            {info.getValue()}
          </Link>
        ) || '--',
      header: 'Client shipment #',
      size: 200,
      initialSize: 200,
      enableResizing: true,
      id: 'shipmentId',
      key: 'client_shipment_id',
      filter: {
        component: InputFilter,
        element: 'textbox',
        operations: inputboxOperations,
        accessor: 'client_shipment_id',
      },
    },
    {
      accessorFn: (row: any) => row?.data?.load_type?.toUpperCase() || '--',
      id: 'shipping_mode',
      cell: (info: any) => info.getValue(),
      header: 'Mode',
      size: 100,
      enableResizing: true,
      initialSize: 100,
      key: 'data.load_type',
      filter: {
        component: InputFilter,
        element: 'dropdown',
        operations: dropdownOperations,
        options: modeOptions,
        accessor: 'data.load_type',
      },
    },
    {
      accessorFn: (row: any) => row?.data?.port_of_loading?.unloc || '--',
      id: 'pol',
      cell: (info: any) => info.getValue(),
      header: 'POL',
      size: 150,
      enableResizing: true,
      initialSize: 150,
      key: 'data.port_of_loading.unloc',
      filter: {
        component: InputFilter,
        element: 'textbox',
        operations: inputboxOperations,
        accessor: 'data.port_of_loading.unloc',
      },
    },
    {
      accessorFn: (row: any) => row?.data?.estimated_time_of_departure?.toDate().toISOString() || '--',
      header: 'ETD',
      cell: (info: any) =>
        info.getValue() !== '--' ? moment(info.getValue()).format('MM/DD/YYYY HH:mm:ss') : info.getValue(),
      size: 180,
      initialSize: 180,
      id: 'etd',
      key: 'data.estimated_time_of_departure',

      // filter: {
      //   component: InputFilter,
      //   element: 'textbox',
      //   operations: inputboxOperations
      // },
    },
    {
      accessorFn: (row: any) => row?.data?.port_of_discharge?.unloc || '--',
      header: 'POD',
      enableResizing: true,
      size: 200,
      initialSize: 200,
      id: 'pod',
      key: 'data.port_of_discharge.unloc',
      filter: {
        component: InputFilter,
        element: 'textbox',
        operations: inputboxOperations,
        accessor: 'data.port_of_discharge.unloc',
      },
    },
    {
      accessorFn: (row: any) => row?.data?.estimated_time_of_arrival?.toDate()?.toISOString() || '--',
      header: 'ETA',
      cell: (info: any) =>
        info.getValue() !== '--' ? moment(info.getValue()).format('MM/DD/YYYY HH:mm:ss') : info.getValue(),
      size: 180,
      initialSize: 180,
      id: 'eta',
      key: 'data.estimated_time_of_arrival',
      // filter: {
      //   component: InputFilter,
      //   element: 'textbox',
      //   operations: inputboxOperations
      // },
    },
    {
      accessorKey: 'validation_result.status_code',
      header: 'Validation status',
      cell: (info: any) => {
        return (
          <Status
            type="Button"
            text={info.getValue() as string}
            disabled={disabledStatus.includes(info.getValue() as string)}
            handleClick={(_) => handleRedirectShipmentDetails(info.row.original.id, true)}
            target={info.getValue() as string}
          />
        )
      },
      size: 200,
      initialSize: 200,
      id: 'validationStatus',
      filter: {
        component: InputFilter,
        element: 'dropdown',
        operations: dropdownOperations,
        options: validationStatusOptions,
        accessor: 'validation_result.status_code',
      },
      key: 'validation_result.status_code',
    },
    {
      accessorFn: (row: any) => getDocumentStatus(row, 'master_bill_of_lading'),
      cell: (info: any) => {
        return <DocumentStatus info={info} docType="master_bill_of_lading" />
      },
      header: 'MBL',
      headerName: 'Status',
      id: 'mbl',
      size: 200,
      initialSize: 200,
      enableSorting: false,
      // filter: {
      //   component: InputFilter,
      //   element: 'dropdown',
      //   operations: dropdownOperations,
      //   options: docOptions,
      // },
    },
    {
      accessorFn: (row: any) => getDocumentStatus(row, 'carrier_arrival_notice'),
      header: 'Carrier AN',
      enableSorting: false,
      cell: (info: any) => <DocumentStatus info={info} docType="carrier_arrival_notice" />,
      size: 200,
      initialSize: 200,
      id: 'carrier',
      // filter: {
      //   component: InputFilter,
      //   element: 'dropdown',
      //   operations: dropdownOperations,
      //   options: docOptions,
      // },
    },
    {
      accessorFn: (row: any) => getDocumentStatus(row, 'commercial_invoice'),
      header: 'CI',
      enableSorting: false,
      cell: (info: any) => <DocumentStatus info={info} docType="commercial_invoice" />,
      size: 200,
      initialSize: 200,
      id: 'ci',
      // filter: {
      //   component: InputFilter,
      //   element: 'dropdown',
      //   operations: dropdownOperations,
      //   options: docOptions,
      // },
    },
    {
      accessorFn: (row: any) => getDocumentStatus(row, 'packing_list'),
      header: 'PL',
      enableSorting: false,
      cell: (info: any) => <DocumentStatus info={info} docType="packing_list" />,
      size: 200,
      initialSize: 200,
      id: 'pl',
      // filter: {
      //   component: InputFilter,
      //   element: 'dropdown',
      //   operations: dropdownOperations,
      //   options: docOptions,
      // },
    },
    // {
    //   accessorFn: (row: any) => getDocumentStatus(row, 'house_bill_of_lading'),
    //   enableSorting: false,
    //   cell: (info: any) => <DocumentStatus info={info} docType="house_bill_of_lading" />,
    //   header: 'HBL',
    //   size: 200,
    //   initialSize: 200,
    //   id: 'hbl',
    //   key: 'validation_result.status_code',
    //   // filter: {
    //   //   component: InputFilter,
    //   //   element: 'dropdown',
    //   //   operations: dropdownOperations,
    //   //   options: docOptions,
    //   // },
    // },
    // {
    //   accessorFn: (row: any) => getDocumentStatus(row, 'pre_alert'),
    //   header: () => 'Pre alert',
    //   enableSorting: false,
    //   cell: (info: any) => (
    //     <Status
    //       type="Button"
    //       text={info.getValue() as string}
    //       disabled={disabledStatus.includes(info.getValue() as string)}
    //       target={info.getValue() as string}
    //     />
    //   ),
    //   id: 'prealert',
    //   size: 200,
    //   initialSize: 200,
    // },
    // {
    //   accessorFn: (row: any) => getDocumentStatus(row, 'automated_manifest_system'),
    //   header: 'AMS',
    //   enableSorting: false,
    //   cell: (info: any) => <DocumentStatus info={info} docType="automated_manifest_system" />,
    //   id: 'ams',
    //   size: 200,
    //   initialSize: 200,
    //   // filter: {
    //   //   component: InputFilter,
    //   //   element: 'dropdown',
    //   //   operations: dropdownOperations,
    //   //   options: docOptions,
    //   // },
    // },
    // {
    //   accessorFn: (row: any) => getDocumentStatus(row, 'cbp_form_7512'),
    //   id: 'cbp_form_7512',
    //   enableSorting: false,
    //   cell: (info: any) => <DocumentStatus info={info} docType="cbp_form_7512" />,
    //   header: 'CBP Form 7512',
    //   size: 200,
    //   initialSize: 200,
    // },
  ])

  const applyFilters = (filter: any) => {
    const req = createPaginateQuery(filter, fetchShipmentsRequest)
    setFetchShipmentsRequest(req)
  }

  const btn_jsx = (
    <Button onClick={() => setIsExportModalOpen(true)}>
      <ArrowDownTrayIcon height={25} width={25} className="!text-white" /> Export
    </Button>
  )

  return (
    <main className="">
      <PageTitle title="Shipments" />
      <div className="max-w-6xl">
        <ActionCards
          cards={cardData}
          loading={shipments.stats.loading}
          filter={filter}
          filterKey="validationStatus"
          setFilter={setFilter}
          setCards={setCardData}
          applyFilters={applyFilters}
        />
      </div>
      <DataTable
        columns={columns}
        data={shipments.shipments}
        totalPages={shipments.totalPages}
        firstToken={shipments.firstToken}
        lastToken={shipments.lastToken}
        loading={shipments.isLoading}
        buttonGroup={btn_jsx}
        searchQuery={fetchShipmentsRequest}
        filter={filter}
        totalCountText={`Total ${shipments.totalData} shipments`}
        setFilter={setFilter}
        setColumns={setColumns}
        setSearchQuery={setFetchShipmentsRequest}
        handleApply={applyFilters}
      />
      <ShipmentExportModal isOpen={isExportModalOpen} setIsOpen={setIsExportModalOpen} />
    </main>
  )
}
