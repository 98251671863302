import React, { ReactNode } from 'react'
import DesktopAppSideBar from './libs/components/desktop-app-side-bar'
import { Navbar } from './libs/components/navbar'
import { SidebarLayout } from './libs/components/sidebar-layout'

export default function Sidebar({ children }: { children: ReactNode }) {
  return (
    <SidebarLayout navbar={<Navbar></Navbar>} sidebar={<DesktopAppSideBar />}>
      {children}
    </SidebarLayout>
  )
}
