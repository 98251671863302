import {
  ArrowRightStartOnRectangleIcon,
  ChevronUpIcon,
  Cog8ToothIcon,
  // Cog8ToothIcon,
  ComputerDesktopIcon,
  DocumentIcon,
  HomeIcon,
  TruckIcon,
} from '@heroicons/react/24/outline'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import content from '../../content/navbar.json'
import { toggleSidebar } from '../reduxStore'
import { handleLogout } from '../reduxStore/slices/auth/authSlice'
import { AppDispatch } from '../reduxStore/store'
import { Avatar } from './avatar'
import { Dropdown, DropdownButton, DropdownDivider, DropdownItem, DropdownLabel, DropdownMenu } from './dropdown'
import { NavbarSection } from './navbar'
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from './sidebar'

const DesktopAppSideBar = () => {
  const icons = {
    '/dashboard': HomeIcon,
    '/documents': DocumentIcon,
    '/shipments': TruckIcon,
    '/login': ComputerDesktopIcon,
    '/login-2': ComputerDesktopIcon,
  }
  const { pathname } = useLocation()

  const dispatch = useDispatch<AppDispatch>()
  const expanded = useSelector((state: any) => state.sidebarReducer)
  const user = useSelector((state: any) => state.authReducer)
  const menuIcon = (
    <span
      className="cursor-pointer p-2 hover:bg-zinc-950/5"
      onClick={() => {
        dispatch(toggleSidebar({ isExpanded: !expanded.isExpanded }))
      }}
    >
      <img src="/static/images/menu.svg" alt="" width={18} height={18} className="rotate-180" />
    </span>
  )

  const navigationItems = (
    <SidebarSection>
      {content?.navitems?.map((item: { href: string; name: string; enabled: boolean; hideInProduction?: boolean }) => {
        const Icon = icons[item?.href as keyof typeof icons]
        const shouldShow = item?.enabled && !(item?.hideInProduction && process.env.REACT_APP_STAGE === 'production')
        return shouldShow ? (
          <SidebarItem key={item.href} to={item?.href} current={pathname === item?.href}>
            <Icon height={!expanded.isExpanded ? 25 : ''} width={!expanded.isExpanded ? 25 : ''} />
            <SidebarLabel>{item?.name}</SidebarLabel>
          </SidebarItem>
        ) : null
      })}
    </SidebarSection>
  )

  return (
    <Sidebar>
      <NavbarSection isExpandedNabar={expanded.isExpanded}>
        <SidebarItem className="cursor-pointer data-[hover]:bg-zinc-100 data-[slot=icon]:*:data-[hover]:fill-zinc-100">
          <img
            src={expanded.isExpanded ? '/static/images/Transparent-01.png' : '/static/images/Transparent-03.png'}
            alt="Brand logo"
            width={expanded.isExpanded ? 200 : 30}
            height={expanded.isExpanded ? 200 : 30}
            onClick={() => dispatch(toggleSidebar({ isExpanded: !expanded.isExpanded }))}
            className="cursor-pointer"
          />
        </SidebarItem>
        {expanded.isExpanded && menuIcon}
      </NavbarSection>
      <SidebarBody>
        {navigationItems}
        <SidebarSpacer />
      </SidebarBody>
      <SidebarFooter className="max-lg:hidden">
        <SidebarSection>
          <Dropdown>
            <DropdownButton as={SidebarItem} className="cursor-pointer">
              <span className="flex min-w-0 cursor-pointer items-center justify-center gap-3">
                <Avatar src="static/images/avatar.svg" className="h-7 w-7" />
                {expanded.isExpanded && (
                  <span className="min-w-0">
                    <span className="block truncate text-sm/5 font-medium text-zinc-950">{user?.user?.email}</span>
                    {/* <span className="block truncate text-xs/5 font-normal text-zinc-500">erica@example.com</span> */}
                  </span>
                )}
              </span>
              {expanded.isExpanded && <ChevronUpIcon className="cursor-pointer" />}
            </DropdownButton>
            {content?.profile?.enabled ? (
              <DropdownMenu className="min-w-64" anchor="top start">
                {content?.profile?.setting?.enabled ? (
                  <>
                    <DropdownItem to={content?.profile?.setting?.href} className="cursor-pointer">
                      <Cog8ToothIcon />
                      <DropdownLabel>{content?.profile?.setting?.label}</DropdownLabel>
                    </DropdownItem>{' '}
                    <DropdownDivider />
                  </>
                ) : null}
                {content?.profile?.logout?.enabled ? (
                  <DropdownItem className="cursor-pointer" onClick={() => dispatch(handleLogout())}>
                    <ArrowRightStartOnRectangleIcon />
                    <DropdownLabel>{content?.profile?.logout?.label}</DropdownLabel>
                  </DropdownItem>
                ) : null}
              </DropdownMenu>
            ) : null}
          </Dropdown>
        </SidebarSection>
      </SidebarFooter>
    </Sidebar>
  )
}

export default DesktopAppSideBar
