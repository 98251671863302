import clsx from 'clsx'
import React from 'react'
import { LoadingIcon } from './loading-icon'

export const Loader = ({ className }: { className?: string }) => {
  return (
    <div className={clsx('opacity-1 z-50 block h-full w-full bg-white', className)}>
      <span className="r-4 opacity-1 relative top-1/3 mx-auto my-0 block h-0 w-0 text-green-500">
        <div role="status">
          <LoadingIcon className="mr-8 h-8 w-8" />
        </div>
      </span>
    </div>
  )
}
