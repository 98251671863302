import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FetchShipmentsById } from '../firebase/model'
import { listenShipmentDetails } from '../firebase/query/shipments'
import {
  updateShipmentDetailLoader,
  updateShipmentDetails,
} from '../reduxStore/slices/shipmentDetails/shipmentDetailsSlice'
import { AppDispatch } from '../reduxStore/store'

const useShipmentDetails = (request: FetchShipmentsById) => {
  const dispatch = useDispatch<AppDispatch>()
  const loadShipmentDetails = (payload: any) => dispatch(updateShipmentDetails(payload))

  useEffect(() => {
    dispatch(updateShipmentDetailLoader())
    const shipmentDetailsListener = listenShipmentDetails(request, loadShipmentDetails)
    return () => shipmentDetailsListener()
  }, [])
}

export default useShipmentDetails
