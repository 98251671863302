import { Accordion } from '@szhsin/react-accordion'
import React from 'react'
import { AccordionItem } from './accordion-items'
import { Badge } from './badge'
import { Button } from './button'
import Drawer from './drawer'

export default function FilterDrawer({
  open,
  setOpen,
  columns,
  filters,
  searchQuery,
  setFilters,
  setSearchQuery,
  handleApply,
  resetHandler,
}: {
  open: boolean
  setOpen: (open: boolean) => void
  columns: any
  filters: any
  searchQuery: any
  setFilters: any
  setSearchQuery: any
  handleApply: any
  resetHandler: any
}) {  
  return (
    <Drawer
      open={open}
      setOpen={setOpen}
      title={'Filters'}
      body={
        <Accordion transition transitionTimeout={200} allowMultiple>
          {columns?.map((col: any) => {
            if (col.columnDef.filter) {
              const Filter = col.columnDef.filter.component
              const isActive = searchQuery?.filter?.filter(
                (query: any) => query.key === col.columnDef.filter.accessor
              ).length
              return (
                <AccordionItem
                  header={
                    <div className="flex w-full justify-between">
                      <div>{col.columnDef.header}</div>
                      {isActive ? (
                        <Badge color="zinc-950" className="mr-5">
                          Active
                        </Badge>
                      ) : null}
                    </div>
                  }
                >
                  <Filter filterKey={col.id} config={col.columnDef.filter} data={filters} setData={setFilters} />
                </AccordionItem>
              )
            }
            return null
          })}
        </Accordion>
      }
      footer={
        <>
          <Button outline onClick={() => setOpen(false)}>
            Cancel
          </Button>
          {searchQuery?.filter?.length ? (
            <Button
              onClick={() => {
                resetHandler()
                setOpen(false)
              }}
            >
              Reset
            </Button>
          ) : null}
          <Button
            onClick={() => {
              handleApply(filters)
              setOpen(false)
            }}
          >
            Apply
          </Button>
        </>
      }
    />
    // <Dialog open={open} onClose={setOpen} className="relative z-10">
    //   <div className="fixed inset-0" />
    //   <div className="fixed inset-0 overflow-hidden">
    //     <div className="absolute inset-0 overflow-hidden">
    //       <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
    //         <DialogPanel
    //           transition
    //           className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
    //         >
    //           <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
    //             <div className="px-4 py-3">
    //               <div className="flex items-start justify-between">
    //                 <DialogTitle className="text-base font-semibold leading-6 text-gray-900">Filters</DialogTitle>
    //                 <div className="ml-3 flex h-7 items-center">
    //                   <button
    //                     type="button"
    //                     onClick={() => setOpen(false)}
    //                     className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
    //                   >
    //                     <span className="absolute -inset-2.5" />
    //                     <span className="sr-only">Close panel</span>
    //                     <XMarkIcon aria-hidden="true" className="h-6 w-6" />
    //                   </button>
    //                 </div>
    //               </div>
    //             </div>
    //             <Divider />
    //             <div className="flex min-h-0 flex-1 flex-col overflow-y-auto py-3">
    //               <div className="relative flex-1 px-4 sm:px-6">
    //                 <Accordion transition transitionTimeout={200} allowMultiple>
    //                   {columns?.map((col: any) => {
    //                     if (col.columnDef.filter) {
    //                       const Filter = col.columnDef.filter.component
    //                       const isActive = searchQuery?.filter?.filter(
    //                         (query: any) => query.key === col.columnDef.filter.accessor
    //                       ).length
    //                       return (
    //                         <AccordionItem
    //                           header={
    //                             <div className="flex w-full justify-between">
    //                               <div>{col.columnDef.header}</div>
    //                               {isActive ? (
    //                                 // <div className="">
    //                                 <Badge color="zinc-950" className="mr-5">
    //                                   Active
    //                                 </Badge>
    //                               ) : // </div>
    //                               null}
    //                             </div>
    //                           }
    //                         >
    //                           <Filter
    //                             filterKey={col.id}
    //                             config={col.columnDef.filter}
    //                             data={filters}
    //                             setData={setFilters}
    //                             isChanged={isChanged}
    //                             setIsChanged={setIsChanged}
    //                           />
    //                         </AccordionItem>
    //                       )
    //                     }
    //                     return null
    //                   })}
    //                 </Accordion>
    //               </div>
    //             </div>
    //             <div className="flex flex-shrink-0 justify-end gap-x-2 px-4 py-4">
    //               <Button outline onClick={() => setOpen(false)}>
    //                 Cancel
    //               </Button>
    //               {searchQuery?.filter?.length ? (
    //                 <Button
    //                   onClick={() => {
    //                     setSearchQuery((query: PaginatedQueryRequest) => ({
    //                       ...query,
    //                       filter: [],
    //                     }))
    //                     setOpen(false)
    //                   }}
    //                   disabled={!isChanged}
    //                 >
    //                   Reset
    //                 </Button>
    //               ) : null}
    //               <Button onClick={handleApplyFilters} disabled={!isChanged}>
    //                 Apply
    //               </Button>
    //             </div>
    //           </div>
    //         </DialogPanel>
    //       </div>
    //     </div>
    //   </div>
    // </Dialog>
  )
}
