import { ActionCardType } from '../../libs/components/action-cards'

export const cardDetails: ActionCardType = [
  {
    id: 'processing_failed',
    title: 'Processing failed',
    value: 0,
    isActive: false,
    request: {
      key: 'processing_result.status_code',
      operation: 'in',
      value: [
        {
          label: 'Failed',
          value: 'failed',
        },
      ],
    },
  },
  //   {
  //     id: 'validation_failed',
  //     title: 'Validation failed',
  //     value: 0,
  //     isActive: false,
  //   },
  {
    id: 'unreceived',
    title: 'Unreceived',
    value: 0,
    isActive: false,
    request: {
      key: 'processing_result.status_code',
      operation: 'in',
      value: [
        {
          label: 'Unreceived',
          value: 'unreceived',
        },
      ],
    },
  },
]

export const documentType = [
  {
    label: 'MBL',
    value: 'master_bill_of_lading',
  },
  {
    label: 'AMS',
    value: 'automated_manifest_system',
  },
  {
    label: 'CBP form 7512',
    value: 'cbp_form_7512',
  },
]
