import React, { Component } from 'react'

// import { AreaHighlight, Highlight, PdfHighlighter, PdfLoader, Popup } from './react-pdf-highlighter'

import type { Content, IHighlight, NewHighlight, ScaledPosition } from './react-pdf-highlighter'

import { Sidebar } from './Sidebar'
import { testHighlights as _testHighlights } from './test-highlights'

import { connect } from 'react-redux'
// import { Loader } from '../../../libs/components/loader'
import './style/App.css'

const testHighlights: any = _testHighlights

interface State {
  url: string
  highlights: Array<IHighlight>
  pdfSrc: string
  imgSrc: string
}

const getNextId = () => String(Math.random()).slice(2)

const parseIdFromHash = () => document.location.hash.slice('#highlight-'.length)

// const resetHash = () => {
//   document.location.hash = ''
// }

// const HighlightPopup = ({ comment }: { comment: { text: string; emoji: string } }) => null

const PRIMARY_PDF_URL = 'http://localhost:3000/static/test.pdf'
const SECONDARY_PDF_URL = 'https://arxiv.org/pdf/1604.02480'

const searchParams = new URLSearchParams(document.location.search)

const initialUrl = searchParams.get('url') || PRIMARY_PDF_URL

// biome-ignore lint/complexity/noBannedTypes: Not sure what to use instead of {}
class App extends Component<any, State> {
  state = {
    url: initialUrl,
    highlights: testHighlights[initialUrl] ? [...testHighlights[initialUrl]] : [],
    pdfSrc: '',
    imgSrc: '',
  }

  resetHighlights = () => {
    this.setState({
      highlights: [],
    })
  }

  toggleDocument = () => {
    const newUrl = this.state.url === PRIMARY_PDF_URL ? SECONDARY_PDF_URL : PRIMARY_PDF_URL

    this.setState({
      url: newUrl,
      highlights: testHighlights[newUrl] ? [...testHighlights[newUrl]] : [],
    })
  }

  scrollViewerTo = (highlight: IHighlight) => {}

  scrollToHighlightFromHash = () => {
    return
    const highlight = this.getHighlightById(parseIdFromHash())
    console.log(highlight, 'highlight')
    if (highlight) {
      this.scrollViewerTo(highlight)
    }
  }

  componentDidMount() {
    window.addEventListener('hashchange', this.scrollToHighlightFromHash, false)
  }

  getHighlightById(id: string) {
    const { highlights } = this.state

    return highlights.find((highlight) => highlight.id === id)
  }

  addHighlight(highlight: NewHighlight) {
    const { highlights } = this.state

    console.log('Saving highlight', highlight)

    this.setState({
      highlights: [{ ...highlight, id: getNextId() }, ...highlights],
    })
  }

  updateHighlight(highlightId: string, position: Partial<ScaledPosition>, content: Partial<Content>) {
    console.log('Updating highlight', highlightId, position, content)

    this.setState({
      highlights: this.state.highlights.map((h) => {
        const { id, position: originalPosition, content: originalContent, ...rest } = h
        return id === highlightId
          ? {
              id,
              position: { ...originalPosition, ...position },
              content: { ...originalContent, ...content },
              ...rest,
            }
          : h
      }),
    })
  }

  render() {
    const { highlights } = this.state
    console.log(this.props.document.docSrc, 'document_1')
    return (
      <>
        <div className="flex h-[100vh]">
          <div
            style={{
              height: '100vh',
              width: '50%',
              position: 'relative',
            }}
          >
            {this.props.document.docSrc && !this.props.document.docSrc?.includes('png') ? (
              // <PdfLoader url={this.props.document.docSrc} beforeLoad={<Loader />}>
              //   {(pdfDocument) => (
              //     <PdfHighlighter
              //       pdfDocument={pdfDocument}
              //       enableAreaSelection={(event) => event.altKey}
              //       onScrollChange={resetHash}
              //       // pdfScaleValue="page-width"
              //       scrollRef={(scrollTo) => {
              //         this.scrollViewerTo = scrollTo

              //         this.scrollToHighlightFromHash()
              //       }}
              //       onSelectionFinished={() => null}
              //       highlightTransform={(
              //         highlight,
              //         index,
              //         setTip,
              //         hideTip,
              //         viewportToScaled,
              //         screenshot,
              //         isScrolledTo
              //       ) => {
              //         const isTextHighlight = !highlight.content?.image
              //         console.log(isTextHighlight, 'isTextHighlight')
              //         const component = isTextHighlight ? (
              //           <Highlight
              //             isScrolledTo={isScrolledTo}
              //             position={highlight.position}
              //             comment={highlight.comment}
              //           />
              //         ) : (
              //           <AreaHighlight
              //             isScrolledTo={isScrolledTo}
              //             highlight={highlight}
              //             onChange={(boundingRect) => {
              //               this.updateHighlight(
              //                 highlight.id,
              //                 { boundingRect: viewportToScaled(boundingRect) },
              //                 { image: screenshot(boundingRect) }
              //               )
              //             }}
              //           />
              //         )

              //         return (
              //           <Popup
              //             popupContent={<HighlightPopup {...highlight} />}
              //             onMouseOver={(popupContent) => setTip(highlight, (highlight) => popupContent)}
              //             onMouseOut={hideTip}
              //             key={index}
              //           >
              //             {component}
              //           </Popup>
              //         )
              //       }}
              //       highlights={highlights}
              //     />
              //   )}
              // </PdfLoader>
              <embed src={this.props.document.docSrc} className='w-full h-full'  />
            ) : (
              <img src={this.props.document.docSrc} />
            )}
          </div>
          <Sidebar
            highlights={highlights}
            resetHighlights={this.resetHighlights}
            toggleDocument={this.toggleDocument}
          />
        </div>
      </>
    )
  }
}

function mapStateToProps(state: any) {
  return { document: state.documentDetailReducer }
}

export default connect(mapStateToProps)(App)
