import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import authReducer from './slices/auth/authSlice'
import documentDetailReducer from './slices/document/documentDetailsSlice'
import documentsReducer from './slices/documents/documentsSlice'
import shipmentDetailsReducer from './slices/shipmentDetails/shipmentDetailsSlice'
import shipmentReducer from './slices/shipments/shipmentsSlice'
import sidebarReducer from './slices/sidebar/sidebarSlice'

export const store = configureStore({
  reducer: {
    sidebarReducer,
    authReducer,
    shipmentReducer,
    shipmentDetailsReducer,
    documentDetailReducer,
    documentsReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
