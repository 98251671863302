import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { auth } from '../../../firebase/config'

const initialState: any = {
  user: null,
  isLoading: false,
  error: null,
}

export const handleLogin = createAsyncThunk(
  'users/login',
  async ({ username, password }: { username: string; password: string }) => {
    const response = await signInWithEmailAndPassword(auth, username, password)
    const d = new Date()
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000)
    let expires = 'expires=' + d.toUTCString()
    document.cookie = `user=${JSON.stringify(response.user.providerData?.[0]) + ';' + expires + ';path=/'}`
    return response.user.providerData?.[0]
  }
)

export const handleLogout = createAsyncThunk('users/logout', async () => {
  try {
    const response = await signOut(auth)
    return response
  } catch (e) {
    console.log('Error-->', e)
    return undefined
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    persistLogin(state, action) {
      state.error = null
      state.user = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleLogin.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(handleLogin.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload
        state.error = null
        state.isLoading = false
      }
    })
    builder.addCase(handleLogin.rejected, (state, action) => {
      state.error = action.error
    })
    builder.addCase(handleLogout.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(handleLogout.fulfilled, (state) => {
      state.user = null
      state.isLoading = false
    })
  },
})

export const { persistLogin } = authSlice.actions
export default authSlice.reducer
