import * as Popover from '@radix-ui/react-popover'
import React from 'react'
import { CheckboxField, CheckboxGroup } from './checkbox'
import { Divider } from './divider'
import { Fieldset, Label } from './fieldset'

import { Button } from './button'

export const DataTableShowColumns = ({ table }: { table: any }) => {
  return (
    <Popover.Root>
      <Popover.Trigger className="hover:bg-zinc-950/2 p-1">
        <Button outline>
          <img src="/static/images/column.svg" alt="Columns" width={20} height={20} />
          Columns
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className="data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade w-[260px] rounded bg-white p-5 shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2),0_0_0_2px_theme(colors.violet7)]"
          sideOffset={5}
        >
          <div className="max-h-[400px] max-w-sm overflow-hidden overflow-y-scroll rounded bg-white p-4">
            <Fieldset>
              <CheckboxGroup>
                {table.getAllLeafColumns()?.map((col: any) => (
                  <div draggable="true" key={col.id}>
                    <CheckboxField key={col?.headerName} className="p-1.5">
                      <input
                        {...{
                          type: 'checkbox',
                          checked: col.getIsVisible(),
                          onChange: col.getToggleVisibilityHandler(),
                          class: 'text-zinc-950 focus:!text-none ring-1',
                        }}
                      />
                      <Label>{col.columnDef.header}</Label>
                    </CheckboxField>
                    <Divider />
                  </div>
                ))}
              </CheckboxGroup>
            </Fieldset>
          </div>
          <Popover.Arrow />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
