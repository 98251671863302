export enum FormLabels {
  shipper_name = 'Name',
  shipper_address_line_1 = 'Address line 1',
  shipper_address_line_2 = 'Address line 2',
  shipper_city = 'City',
  shipper_state = 'State',
  shipper_zip_code = 'Zip code',
  shipper_country = 'Country',
  consignee_name = 'Name',
  consignee_address_line_1 = 'Address line 1',
  consignee_address_line_2 = 'Address line 2',
  consignee_city = 'City',
  consignee_state = 'State',
  consignee_zip_code = 'Zip code',
  consignee_country = 'Country',
  'destination_agent_name' = 'Name',
  'destination_agent_address_line_1' = 'Address line 1',
  'destination_agent_address_line_2' = 'Address line 2',
  'destination_agent_city' = 'City',
  'destination_agent_state' = 'State',
  'destination_agent_zip_code' = 'Zip code',
  'destination_agent_country' = 'Country',
  'notify_party_name' = 'Name',
  'notify_party_address_line_1' = 'Address line 1',
  'notify_party_address_line_2' = 'Address line 2',
  'notify_party_city' = 'City',
  'notify_party_state' = 'State',
  'notify_party_zip_code' = 'Zip code',
  'notify_party_country' = 'Country',
  'notify_party_emails' = 'Emails',
  'freight_mode' = 'Mode',
  'freight_terms' = 'Terms',
  'freight_service' = 'Service',
  'incoterm' = 'Incoterm',
  'house_bill_of_lading_release_type' = 'Release type',
  'number_of_origin_bill_of_lading' = 'Original BOL',
  'house_bill_of_lading_number' = 'HBL #',
  'automated_manifest_system_number' = 'AMS #',
  'vessel' = 'Vessel',
  'voyage' = 'Voyage',
  'port_of_loading' = 'POL',
  'port_of_discharge' = 'POD',
  'place_of_receipt' = 'Place of receipt',
  'place_of_delivery' = 'CFS',
  'shipped_on_board_date' = 'Date',
  'estimated_time_of_departure' = 'ETD',
  'estimated_time_of_arrival' = 'ETA',
  'place_of_issue' = 'Place of issue',
  'date_of_issue' = 'Date of issue',
  'total_containers' = 'Total containers',
  'total_weight' = 'Weight',
  'total_volume' = 'Volume',
  'total_packages' = 'Inner Piece Count',
  'total_pallets' = 'Outer Piece Count',
  'weight_uom' = 'Weight UOM',
  'volume_uom' = 'Volume UOM',
  'shipment_reference_id' = 'Ref #',
  'booking_reference' = 'Booking ref',
  'ocean_carrier' = 'Carrier',
  master_bill_of_lading_number = 'Carrier BL#',
  international_maritime_organization_number = 'IMO #',
  vessel_flag = 'Flag',
  'last_foreign_port' = 'Last foreign port',
  'first_usa_port' = 'First USA port',
  'container_number' = 'Container #',
  'seal_number' = 'Seal #',
  'container_type' = 'Type',
  'package_count' = 'Inner Piece Count',
  'package_uom' = 'Inner Piece UOM',
  'pallet_count' = 'Outer Piece Count',
  'weight' = 'Weight',
  'volume' = 'Volumne',
  'hs_code' = 'HS code',
  'item_description' = 'Description',
  'total_gross_weight' = 'Gross weight',
  'total_net_weight' = 'Net weight',
  gross_weight = 'Gross weight',
  product_description = 'Product description',
  product_number = 'Product #',
  purchase_order_number = 'Order #',
  unit_quantity = 'Unit quantity',
  net_weight = 'Net weight',
  'exporter_name' = 'Name',
  'exporter_address_line_1' = 'Address line 1',
  'exporter_address_line_2' = 'Address line 2',
  'exporter_city' = 'City',
  'exporter_state' = 'State',
  'exporter_zip_code' = 'Zip code',
  exporter_country = 'Country',
  'importer_name' = 'Name',
  'importer_address_line_1' = 'Address line 1',
  'importer_address_line_2' = 'Address line 2',
  'importer_city' = 'City',
  'importer_state' = 'State',
  'importer_zip_code' = 'Zip code',
  importer_country = 'Country',
  'invoice_number' = 'Invoice #',
  'invoice_date' = 'Invoice date',
  'total_units' = 'Total units',
  'total_cost' = 'Total cost',
  'currency' = 'Currency',
  'product_hscode' = 'HS code',
  unit_price = 'Unit price',
  total_price = 'Total price',
  last_free_date = 'Last free date',
  total_charges = 'Total charges',
  'entry_number' = 'Entry #',
  'class_of_entry' = 'Entry class',
  'inbond_date' = 'Inbond date',
  'bonded_carrier' = 'Bonded carrier',
  'consignee' = 'Consignee',
  'importer' = 'Importer',
}

const shipper = {
  name: 'Shipper',
  json_keys: [
    'shipper_name',
    'shipper_address_line_1',
    'shipper_address_line_2',
    'shipper_city',
    'shipper_state',
    'shipper_zip_code',
    'shipper_country',
  ],
}

const exporter = {
  name: 'Exporter',
  json_keys: [
    'exporter_name',
    'exporter_address_line_1',
    'exporter_address_line_2',
    'exporter_city',
    'exporter_state',
    'exporter_zip_code',
    'exporter_country',
  ],
}

const importer = {
  name: 'Importer',
  json_keys: [
    'importer_name',
    'importer_address_line_1',
    'importer_address_line_2',
    'importer_city',
    'importer_state',
    'importer_zip_code',
    'importer_country',
  ],
}

const consignee = {
  name: 'Consignee',
  json_keys: [
    'consignee_name',
    'consignee_address_line_1',
    'consignee_address_line_2',
    'consignee_city',
    'consignee_state',
    'consignee_zip_code',
    'consignee_country',
  ],
}

const notifyParty = {
  name: 'Notify party',
  json_keys: [
    'notify_party_name',
    'notify_party_address_line_1',
    'notify_party_address_line_2',
    'notify_party_city',
    'notify_party_state',
    'notify_party_zip_code',
    'notify_party_country',
    'notify_party_emails',
  ],
}

const designation = {
  name: 'Destination agent',
  json_keys: [
    'destination_agent_name',
    'destination_agent_address_line_1',
    'destination_agent_address_line_2',
    'destination_agent_city',
    'destination_agent_state',
    'destination_agent_zip_code',
    'destination_agent_country',
  ],
}

const containers = {
  name: 'Containers',
  array_contains: true,
  key: 'containers',
  text_area_fields: ['item_description'],
}

const shipmentDetails = {
  name: 'Shipment details',
  json_keys: [
    'freight_mode',
    'freight_terms',
    'freight_service',
    'incoterm',
    'house_bill_of_lading_release_type',
    'number_of_origin_bill_of_lading',
    'master_bill_of_lading_number',
    'house_bill_of_lading_number',
    'automated_manifest_system_number',
    'vessel',
    'voyage',
    'port_of_loading',
    'port_of_discharge',
    'place_of_receipt',
    'place_of_delivery',
    'shipped_on_board_date',
    'estimated_time_of_departure',
    'estimated_time_of_arrival',
    'place_of_issue',
    'date_of_issue',
    'total_containers',
    'total_weight',
    'total_volume',
    'total_packages',
    'total_pallets',
    'weight_uom',
    'volume_uom',
    'shipment_reference_id',
  ],
}

export const FormsByType = {
  house_bill_of_lading: [
    { ...shipper },
    { ...consignee },
    { ...notifyParty },
    { ...designation },
    { ...shipmentDetails },
    { ...containers },
  ],
  automated_manifest_system: [
    { ...shipper },
    { ...consignee },
    {
      name: 'Shipment details',
      json_keys: [
        'booking_reference',
        'ocean_carrier',
        'master_bill_of_lading_number',
        'vessel',
        'voyage',
        'international_maritime_organization_number',
        'vessel_flag',
        'place_of_receipt',
        'port_of_loading',
        'last_foreign_port',
        'first_usa_port',
        'port_of_discharge',
        'freight_mode',
        'estimated_time_of_departure',
        'estimated_time_of_arrival',
        'automated_manifest_system_number',
        'total_containers',
        'total_weight',
        'total_volume',
        'total_packages',
        'total_pallets',
        'weight_uom',
        'volume_uom',
        'shipment_reference_id',
      ],
    },
    { ...containers },
  ],
  master_bill_of_lading: [
    { ...shipper },
    { ...consignee },
    { ...notifyParty },
    {
      name: 'Shipment details',
      json_keys: [
        'freight_mode',
        'freight_terms',
        'freight_service',
        'master_bill_of_lading_number',
        'vessel',
        'voyage',
        'port_of_loading',
        'port_of_discharge',
        'place_of_receipt',
        'place_of_delivery',
        'shipped_on_board_date',
        'estimated_time_of_departure',
        'estimated_time_of_arrival',
        'place_of_issue',
        'date_of_issue',
        'total_volume',
        'total_containers',
        'total_packages',
        'total_weight',
        'weight_uom',
        'volume_uom',
        'shipment_reference_id',
      ],
    },
    { ...containers },
  ],
  carrier_arrival_notice: [
    { ...shipper },
    { ...consignee },
    { ...notifyParty },
    {
      name: 'Shipment details',
      json_keys: [
        'freight_mode',
        'freight_terms',
        'freight_service',
        'master_bill_of_lading_number',
        'house_bill_of_lading_number',
        'vessel',
        'voyage',
        'port_of_loading',
        'port_of_discharge',
        'place_of_receipt',
        'place_of_delivery',
        'estimated_time_of_departure',
        'estimated_time_of_arrival',
        'last_free_date',
        'total_containers',
        'total_packages',
        'total_weight',
        'total_volume',
        'weight_uom',
        'volume_uom',
        'total_charges',
        'currency',
        'shipment_reference_id',
      ],
    },
    { ...containers },
  ],
  commercial_invoice: [
    { ...exporter },
    { ...importer },
    {
      name: 'Invoice details',
      json_keys: ['invoice_number', 'invoice_date', 'total_units', 'total_cost', 'currency', 'shipment_reference_id'],
    },
    {
      name: 'Products',
      array_contains: true,
      key: 'products',
      text_area_fields: ['product_description'],
    },
  ],
  packing_list: [
    { ...exporter },
    { ...importer },
    {
      name: 'Packing details',
      json_keys: [
        'total_gross_weight',
        'total_net_weight',
        'total_containers',
        'total_weight',
        'total_volume',
        'total_packages',
        'total_pallets',
        'weight_uom',
        'volume_uom',
        'shipment_reference_id',
        'total_units',
      ],
    },
    {
      name: 'Products',
      array_contains: true,
      key: 'products',
      text_area_fields: ['product_description'],
    },
  ],
  cbp_form_7512: [
    {
      name: 'Inbond details',
      json_keys: [
        'entry_number',
        'class_of_entry',
        'inbond_date',
        'master_bill_of_lading_number',
        'house_bill_of_lading_number',
        'automated_manifest_system_number',
        'bonded_carrier',
        'consignee',
        'importer',
      ],
    },
  ],
}
