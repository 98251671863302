import { onAuthStateChanged } from 'firebase/auth'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { auth } from '../firebase/config'
import { persistLogin } from '../reduxStore/slices/auth/authSlice'

export const useAuthenticate = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user?.providerData?.[0]) {
        dispatch(persistLogin(user.providerData?.[0]))
        if (pathname.includes('login')) {
          navigate('/shipments')
        }
        return
      } else {
        return navigate('/login')
      }
    })
    return () => unsubscribe()
  }, [pathname])
}
