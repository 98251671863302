import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  currentPageNumber: 0,
  totalPages: 0,
  documents: [],
  firstToken: '',
  lastToken: '',
  totalData: 0,
  isLoading: false,
  error: null,
  stats: {
    processing_failed: 0,
    unreceived: 0,
    loading: false,
  },
}

const documents = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    updateLoader(state) {
      state.isLoading = true
    },
    updateDocsList(state, action) {
      state.documents = action.payload.documents
      state.totalPages = action.payload.totalPages
      state.firstToken = action.payload.firstToken
      state.lastToken = action.payload.lastToken
      state.totalData = action.payload.totalData
      state.error = null
      state.isLoading = false
    },
    updateDocsListStatsLoading(state) {
      state.stats.loading = true
    },
    updateDocsListStats(state, action) {
      state.stats = {
        ...action.payload,
        loading: false,
      }
    },
  },
})

export const { updateDocsList, updateLoader, updateDocsListStatsLoading, updateDocsListStats } = documents.actions
export default documents.reducer
