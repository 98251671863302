import { ActionCardType } from '../action-cards'

export enum DocumentType {
  master_bill_of_lading = 'MBL',
  carrier_arrival_notice = 'Carrier AN',
  commercial_invoice = 'CI',
  packing_list = 'PL',
  isf = 'ISF',
  pre_alert = 'Pre alert',
  house_bill_of_lading = 'HBL',
  rail_arrival_notice = 'Rail Arrival Notice',
  rail_arrival_notice_reminder = 'Rail Arrival Notice Reminder',
  rail_pre_arrival_notice = 'Rail Pre Arrival Notice',
  ap_invoice = 'AP Invoice',
  osd_report = 'OSD Report',
  isf_hold_notice = 'ISF Hold Notice',
  release_order = 'Release Order',
  permit_to_transfer = 'Permit To TRANSFER',
  freight_manifest = 'Freight Manifest',
  booking_confirmation = 'Booking Confirmation',
  automated_manifest_system = 'AMS',
  cbp_form_7512 = 'CBP Form 7512',
}

export const disabledStatus = ['in_progress', 'Not validated', 'Mixed']
export const successVarientStatus = ['partial_ok', 'success', 'all_ok']

export const shipmentStats: ActionCardType = [
  {
    id: 'needs_attention',
    title: 'Needs attention',
    value: 0,
    isActive: false,
    request: {
      key: 'validation_result.status_code',
      operation: 'in',
      value: [
        {
          label: 'Discrepancy',
          value: 'error',
        },
      ],
    },
  },
  {
    id: 'missing_documents',
    title: 'Missing documents',
    value: 0,
    isActive: false,
  },
  {
    id: 'in_progress',
    title: 'In progress',
    value: 0,
    isActive: false,
    request: {
      key: 'validation_result.status_code',
      operation: 'in',
      value: [
        {
          label: 'Partial OK',
          value: 'partial_ok',
        },
        {
          label: 'Discrepancy',
          value: 'error',
        },
      ],
    },
  },
  {
    id: 'completed',
    title: 'Completed',
    value: 0,
    isActive: false,
    request: {
      key: 'validation_result.status_code',
      operation: 'in',
      value: [
        {
          label: 'All OK',
          value: 'all_ok',
        },
      ],
    },
  },
]
