import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon'
import { AccordionItem as Item, AccordionItemProps } from '@szhsin/react-accordion'
import React from 'react'

export const AccordionItem = ({ header, ...rest }: AccordionItemProps) => (
  <Item
    {...rest}
    header={({ state: { isEnter } }) => (
      <>
        {header}
        <div className={`ml-auto transition-transform duration-200 ${isEnter && 'rotate-180'}`}>
          <ChevronDownIcon height={18} width={18} className="ease-out" />
        </div>
      </>
    )}
    // className="border-b"
    buttonProps={{
      className: ({ isEnter }) =>
        `rounded-lg flex w-full p-3 text-left hover:bg-zinc-950/5 ${isEnter && 'bg-zinc-950/5'} text-base`,
    }}
    contentProps={{
      className: 'transition-height duration-200 ease-out',
    }}
    panelProps={{ className: 'p-3' }}
  />
)
