import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FetchDocumentDetails } from '../firebase/model'
import { fetchDocumentById } from '../firebase/query/document'
import { getDocument, updateGetDocumentLoader } from '../reduxStore/slices/document/documentDetailsSlice'
import { AppDispatch } from '../reduxStore/store'

const useDocumentDetails = (request: FetchDocumentDetails) => {
  const dispatch = useDispatch<AppDispatch>()
  const loadDocument = (payload: any) => dispatch(getDocument(payload))

  useEffect(() => {
    dispatch(updateGetDocumentLoader())
    const docDetailsListener = fetchDocumentById(request, loadDocument)
    return () => docDetailsListener()
  }, [])
}

export default useDocumentDetails
