import {
  collection,
  // collectionGroup,
  endBefore,
  getDocs,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from '@firebase/firestore'
import { where, WhereFilterOp } from 'firebase/firestore'
import { timestampToLocaleString } from '../../utils'
import { db } from '../config'
import { ExportDataRequest, FetchShipmentById, FetchShipmentsById, PaginatedQueryRequest } from '../model'
import { getCountUntilCurrentPage, getTotalCount } from '../utils'

export async function getShipments(request: PaginatedQueryRequest, callback?: any) {
  const shipmentViewCollection = collection(db, 'shipments')
  let q = query(shipmentViewCollection, limit(request.pageSize), orderBy('created_at'))
  if (request.direction === 'next' && request.pageToken) {
    q = query(q, startAfter(request.pageToken), limit(request.pageSize))
  } else if (request.direction === 'prev' && request.pageToken) {
    q = query(q, endBefore(request.pageToken), limitToLast(request.pageSize))
  } else {
    q = query(q, limit(request.pageSize))
  }
  const querySnapshot = await getDocs(q)
  const firstToken = querySnapshot.docs[0]
  const lastToken = querySnapshot.docs[querySnapshot.docs.length - 1]
  const shipments = querySnapshot.docs.map((doc) => {
    const data = doc.data()
    return {
      ...data,
      created_at: timestampToLocaleString(data.created_at.seconds),
      updated_at: timestampToLocaleString(data.updated_at.seconds),
    }
  })
  const totalCount = await getTotalCount(shipmentViewCollection)
  const totalPages = Math.ceil(totalCount / request.pageSize)

  const countUntilCurrentPage = await getCountUntilCurrentPage(shipmentViewCollection, request, firstToken)
  let currentPageNumber = Math.floor(countUntilCurrentPage / request.pageSize) + 1

  return {
    currentPageNumber,
    totalPages,
    shipments,
    firstToken: firstToken,
    lastToken: lastToken,
  }
}

export const shipmentsListener = (request: PaginatedQueryRequest, callback: any) => {
  const shipmentViewCollection = collection(db, 'shipments')
  let pageCount = query(
    shipmentViewCollection,
    where('org_id', '==', request.orgId),
    orderBy(request.sort?.key || 'created_at', request.sort?.value || 'desc')
  )
  // if (request.filter?.key && request.filter?.value) {
  //   pageCount = query(pageCount, where(request.filter.key, '==', request.filter.value))
  // }

  if (request.filter?.length) {
    request.filter?.forEach((filterItem) => {
      pageCount = query(pageCount, where(filterItem.key, filterItem.operation, filterItem.value))
    })
  }
  let q = pageCount
  if (request.direction === 'next' && request.pageToken) {
    q = query(q, startAfter(request.pageToken), limit(request.pageSize))
  } else if (request.direction === 'prev' && request.pageToken) {
    q = query(q, endBefore(request.pageToken), limitToLast(request.pageSize))
  } else {
    q = query(q, limit(request.pageSize))
  }
  return onSnapshot(q, async () => {
    const querySnapshot = await getDocs(q)
    const firstToken = querySnapshot.docs?.[0]
    const lastToken = querySnapshot.docs?.[querySnapshot.docs.length - 1]
    const shipments = querySnapshot.docs.map((doc: any) => {
      const data = doc.data()
      return {
        ...data,
        created_at: data.created_at.toMillis(),
        updated_at: data.updated_at.toMillis(),
      }
    })

    const totalData = await getTotalCount(pageCount)
    
    callback?.({
      shipments,
      firstToken,
      lastToken,
      totalPages: Math.ceil(totalData / request.pageSize),
      totalData
    })
  })
}

export const listenerForShipmentStats = (request: PaginatedQueryRequest, callback?: any) => {
  const shipmentViewCollection = collection(db, 'shipments')
  let q = query(shipmentViewCollection, where('org_id', '==', request.orgId))
  const needsAttention = query(q, where('validation_result.status_code', '==', 'error'))
  const inProgress_partialok = query(q, where('validation_result.status_code', '==', 'partial_ok'))
  const inProgress_error = query(q, where('validation_result.status_code', '==', 'error'))
  const completed = query(q, where('validation_result.status_code', '==', 'all_ok'))
  return onSnapshot(q, async (snapShot) => {
    let missingDocuments = 0
    const needsAttentionQuerySnapshot = await getDocs(needsAttention)
    const inProgressPartialOKQuerySnapshot = await getDocs(inProgress_partialok)
    const inProgressErrorQuerySnapshot = await getDocs(inProgress_error)
    const completedQuerySnapshot = await getDocs(completed)
    snapShot.docs.forEach((doc: any) => {
      const unreceivedDocs =
        doc.data()?.shipping_documents?.filter((d: any) => d.processing_result_status_code === 'unreceived') || []
      if (unreceivedDocs?.length) {
        missingDocuments = missingDocuments + 1
      }
    })
    callback?.({
      needsAttention: needsAttentionQuerySnapshot?.docs?.length,
      inProgress: inProgressPartialOKQuerySnapshot.docs.length + inProgressErrorQuerySnapshot.docs.length,
      completed: completedQuerySnapshot.docs.length,
      missingDocuments,
    })
  })
}

const searchKeyMap: {
  [key: string]: {
    field: string;
    operand: WhereFilterOp;
  }
} = {
  containerId: {
    field: 'labels.ocean_container_numbers',
    operand: 'array-contains'
  },
  hblNumber: {
    field: 'data.house_bill_of_lading_number',
    operand: '=='
  }
};

export const filterShipmentsBySearchKey = async (request: ExportDataRequest) => {
  const shipmentViewCollection = collection(db, 'shipments')
  const searchConfig = searchKeyMap[request.exportKeyType];

  if (!searchConfig) {
    throw new Error('Invalid exportKeyType');
  }

  const q = query(
    shipmentViewCollection,
    where('org_id', '==', request.orgId),
    where(searchConfig.field, searchConfig.operand, request.exportKeyValue),
    orderBy('created_at', 'desc')
  )

  const querySnapShot = await getDocs(q)
  return querySnapShot.docs.map((doc: any) => {
    const d = doc.data()
    return {
      ...d,
    }
  })
}

export const filterShipmentsByShipmentId = async (request: FetchShipmentById) => {
  const shipmentViewCollection = collection(db, 'shipments')
  let q = query(
    shipmentViewCollection,
    where('org_id', '==', request.orgId),
    where('id', '==', request.shipmentId),
    orderBy('created_at', 'desc')
  )
  const querySnapShot = await getDocs(q)
  return querySnapShot.docs.map((doc: any) => {
    const d = doc.data()
    return {
      ...d,
    }
  })
}

export const listenShipmentDetails = (request: FetchShipmentsById, callback: (shipment: any) => void) => {
  const shipmentViewCollection = collection(db, 'shipments')
  let q = query(shipmentViewCollection, where('org_id', '==', request.orgId), where('id', '==', request.shipmentId))

  return onSnapshot(q, async () => {
    const querySnapshot = await getDocs(q)
    const shipment = querySnapshot.docs.map((doc: any) => {
      const data = doc.data()
      return {
        ...data,
        created_at: data.created_at.toMillis(),
        updated_at: data.updated_at.toMillis(),
      }
    })
    callback?.(shipment[0])
  })
}
