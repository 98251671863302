import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { PaginatedQueryRequest } from '../firebase/model'
import { documentStats, fetchDocuments } from '../firebase/query/document'
import {
  updateDocsList,
  updateDocsListStats,
  updateDocsListStatsLoading,
  updateLoader,
} from '../reduxStore/slices/documents/documentsSlice'
import { AppDispatch } from '../reduxStore/store'

const useDocuments = (request: PaginatedQueryRequest) => {
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(updateLoader())
    const documents = fetchDocuments(request, (payload: any) => {
      dispatch(updateDocsList(payload))
    })
    return () => documents()
  }, [request])

  useEffect(() => {
    dispatch(updateDocsListStatsLoading())
    const docStats = documentStats(request, (payload: any) => dispatch(updateDocsListStats(payload)))
    return () => docStats()
  }, [])
}

export default useDocuments
