import {
  collection,
  endBefore,
  getDocs,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore'
import { db } from '../config'
import { FetchDocumentDetails, PaginatedQueryRequest } from '../model'
import { getTotalCount } from '../utils'

export const fetchDocumentById = (request: FetchDocumentDetails, callback: any) => {
  const documentViewCollection = collection(db, 'shipping-documents')
  let q = query(
    documentViewCollection,
    where('org_id', '==', request.orgId),
    where('id', '==', request.docId),
    orderBy('created_at', 'desc')
  )
  return onSnapshot(q, async () => {
    const querySnapshot = await getDocs(q)
    const documents = querySnapshot.docs.map((doc: any) => {
      const data = doc.data()
      return {
        ...data,
        created_at: data.created_at.toMillis(),
        updated_at: data.updated_at.toMillis(),
      }
    })
    callback?.(documents[0])
  })
}

export const fetchDocuments = (request: PaginatedQueryRequest, callback: any) => {
  const documentCollection = collection(db, 'shipping-documents')
  let pageCount = query(
    documentCollection,
    where('org_id', '==', request.orgId),
    orderBy(request.sort?.key || 'created_at', request.sort?.value || 'desc')
  )
  // if (request.filter?.key && request.filter?.value) {
  //   pageCount = query(pageCount, where(request.filter.key, '==', request.filter.value))
  // }

  if (request.filter?.length) {
    request.filter?.forEach((filterItem) => {
      pageCount = query(pageCount, where(filterItem.key, filterItem.operation, filterItem.value))
    })
  }
  let q = pageCount
  if (request.direction === 'next' && request.pageToken) {
    q = query(q, startAfter(request.pageToken), limit(request.pageSize))
  } else if (request.direction === 'prev' && request.pageToken) {
    q = query(q, endBefore(request.pageToken), limitToLast(request.pageSize))
  } else {
    q = query(q, limit(request.pageSize))
  }
  return onSnapshot(q, async () => {
    const querySnapshot = await getDocs(q)
    const firstToken = querySnapshot.docs?.[0]
    const lastToken = querySnapshot.docs?.[querySnapshot.docs.length - 1]
    const documents: any[] = []
    for (const doc of querySnapshot.docs) {
      const data = doc.data()
      // const payload = {
      //   orgId: request?.orgId,
      //   shipmentId: data.shipment_ids?.[0],
      // }
      // const a: any = await filterShipmentsByShipmentId(payload)
      const docDetails = {
        ...data,
        // validationStatus: a?.[0]?.validation_result?.status_code || '',
        created_at: data.created_at?.toMillis(),
        updated_at: data.updated_at.toMillis(),
      }
      documents.push(docDetails)
    }

    const totalData = await getTotalCount(pageCount)
    callback?.({
      documents,
      firstToken,
      lastToken,
      totalPages: Math.ceil(totalData / request.pageSize),
      totalData
    })
  })
}

export const documentStats = (request: PaginatedQueryRequest, callback?: any) => {
  const shipmentViewCollection = collection(db, 'shipping-documents')
  let q = query(shipmentViewCollection, where('org_id', '==', request.orgId))
  const failedRecord = query(q, where('processing_result.status_code', '==', 'failed'))
  const unrecievedCount = query(q, where('processing_result.status_code', '==', 'unreceived'))
  return onSnapshot(q, async (snapShot) => {
    const failedQuerySnapshot = await getDocs(failedRecord)
    const unrecievedQuerySnapshot = await getDocs(unrecievedCount)
    callback?.({
      processing_failed: failedQuerySnapshot?.docs?.length,
      unreceived: unrecievedQuerySnapshot.docs.length,
    })
  })
}
