import React from 'react'
import { Filter } from '../firebase/model'
import ActiveFilterTag from './active-filter-tag'

interface ActiveFilters {
  filters: Filter[]
  columns: any[]
  handleResetFilter: (key: string) => void
}

const ActiveFilters = ({ filters, columns, handleResetFilter }: ActiveFilters) => {
  return (
    <>
      {filters?.map((f) => {
        return <ActiveFilterTag filter={f} columns={columns} handleResetFilter={handleResetFilter} />
      })}
    </>
  )
}
export default ActiveFilters
