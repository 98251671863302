import { XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Filter } from '../firebase/model'

interface ActiveFilter {
  columns: any[]
  filter: Filter
  handleResetFilter: (key: string) => void
}

const ActiveFilterTag = ({ columns, filter, handleResetFilter }: ActiveFilter) => {
  const col = columns?.filter((col: any) => col?.filter?.accessor == filter.key)  
  const header = col?.[0]?.header || ''
  const operation = col?.[0]?.filter?.operations?.filter((operation: any) => operation?.value === filter.operation)?.[0]
    ?.label
  const optionVal = col?.[0]?.filter?.options
    ?.filter((option: any) => filter.value?.includes(option?.value))
    ?.map((el: any) => el.label)
    ?.join(', ')
  return (
    <div className="flex items-center rounded-lg bg-zinc-950/5 px-4 py-2 text-sm">
      <span>
        <strong className="font-semibold">{header}</strong> {operation}&nbsp;
        {filter?.value ? (typeof filter.value === 'string' ? filter?.value : optionVal) : null} &nbsp;&nbsp;
      </span>
      <span className="hover: cursor-pointer" onClick={() => handleResetFilter(filter.key)}>
        <XMarkIcon height={15} width={15} />
      </span>
    </div>
  )
}

export default ActiveFilterTag;