import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { OverrideShipmentRequest } from './model'

const initialState: any = {
  shipment: null,
  isLoading: false,
  error: null,
  overriddenShipmentDetails: {
    loader: false,
    response: {
      status: '',
      message: '',
    },
  },
}

export const overrideShipmentValidation = createAsyncThunk(
  'update/shipment',
  async (overrideShipmentRequest: OverrideShipmentRequest, callback: any) => {
    const shipmentId = overrideShipmentRequest.id
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/shipments/${shipmentId}`
    const response = await axios.patch(
      apiUrl,
      {
        id: overrideShipmentRequest.id,
        version: overrideShipmentRequest.version,
        data: overrideShipmentRequest.data,
      },
      {
        headers: {
          Authorization: `Bearer ${overrideShipmentRequest.token}`,
          'Content-Type': 'application/json',
        },
      }
    )
    return response
  }
)

const shipmentDetails = createSlice({
  name: 'shipmentsDetails',
  initialState,
  reducers: {
    updateShipmentDetailLoader(state) {
      state.isLoading = true
    },
    updateShipmentDetails(state, action) {
      state.shipment = action.payload
      state.isLoading = false
    },
    resetToaster(state) {
      state.overriddenShipmentDetails.response = {
        status: '',
        message: '',
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(overrideShipmentValidation.pending, (state, action) => {
      state.overriddenShipmentDetails.loader = true
    })
    builder.addCase(overrideShipmentValidation.fulfilled, (state, action) => {
      state.overriddenShipmentDetails.loader = false
      state.overriddenShipmentDetails.response = {
        status: 'success',
        message: 'Saved successfully!',
      }
    })
    builder.addCase(overrideShipmentValidation.rejected, (state, action) => {
      state.overriddenShipmentDetails.loader = false
      state.overriddenShipmentDetails.response = {
        status: 'failed',
        message: 'Failed to save!',
      }
    })
  },
})

export const { updateShipmentDetailLoader, updateShipmentDetails, resetToaster } = shipmentDetails.actions
export default shipmentDetails.reducer
