import React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import DocumentDetails from './containers/Document/details'
import Document from './containers/Document/document'
import Login2 from './containers/Login/login-2'
import ShipmentDetails from './containers/Shipments/Details/ShipmentDetails'
import Shipments from './containers/Shipments/shipments'
import { useAuthenticate } from './libs/hooks/useAuthenticate'
import Sidebar from './Sidebar'

function App() {
  useAuthenticate()
  return (
    <Routes>
      {/* <Route path="/login" element={<Login />} /> */}
      <Route path="/login" element={<Login2 />} />
      {/* <Route path="/document/details" element={<DocumentDetails />} /> */}
      <Route
        path="/*"
        element={
          <Sidebar>
            <Routes>
              {/* <Route path="dashboard" element={<Dashboard />} />*/}
              <Route path="documents" element={<Document />} />
              <Route path="shipments" element={<Shipments />} />
              <Route path="shipments/:shipmentId" element={<ShipmentDetails />} />
              <Route path="document/:docId" element={<DocumentDetails />} />
            </Routes>
          </Sidebar>
        }
      />
    </Routes>
  )
}

export default App
