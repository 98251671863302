import {
  CollectionReference,
  endBefore,
  getCountFromServer,
  getDocs,
  query,
  Query,
  QueryDocumentSnapshot,
} from '@firebase/firestore'
import { orderBy, where } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import { PaginatedQueryRequest } from './model'

export async function getTotalCount(query: Query) {
  try {
    const countSnapshot = await getCountFromServer(query)
    return countSnapshot.data().count
  } catch (e) {
    return 0
  }
}

export async function getCountUntilCurrentPage(
  tasksCollection: CollectionReference,
  request: PaginatedQueryRequest,
  firstToken: QueryDocumentSnapshot
) {
  let q = query(
    tasksCollection,
    where('org_id', '==', 'clt-freightmatedemo'),
    orderBy('created_at', 'desc'),
    endBefore(firstToken)
  )

  const countSnapshot = await getDocs(q)
  return countSnapshot.size
}

export async function getGSReference(gsUrl: string, cb: any) {
  const storage = getStorage()
  getDownloadURL(ref(storage, gsUrl))
    .then((url) => {
      cb(url)
    })
    .catch((error) => {
      // Handle any errors
    })
}
