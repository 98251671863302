import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { PaginatedQueryRequest } from '../firebase/model'
import { listenerForShipmentStats, shipmentsListener } from '../firebase/query/shipments'
import {
  updateLoader,
  updateShipment,
  updateShipmentStats,
  updateStatsLoading,
} from '../reduxStore/slices/shipments/shipmentsSlice'
import { AppDispatch } from '../reduxStore/store'

const useShipments = (request: PaginatedQueryRequest) => {
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(updateLoader())
    const shipments = shipmentsListener(request, (payload: any) => dispatch(updateShipment(payload)))
    return () => shipments()
  }, [request])

  useEffect(() => {
    dispatch(updateStatsLoading())
    const shipmentsStats = listenerForShipmentStats(request, (payload: any) => dispatch(updateShipmentStats(payload)))
    return () => shipmentsStats()
  }, [])
}

export default useShipments
