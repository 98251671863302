import { CheckBadgeIcon, CheckCircleIcon, ClockIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { Accordion } from '@szhsin/react-accordion'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ResizableBox } from 'react-resizable'
import { useParams } from 'react-router-dom'
import { AccordionItem } from '../../../libs/components/accordion-items'
import { Badge, Colors } from '../../../libs/components/badge'
import { Breadcrumb } from '../../../libs/components/breadcrumb'
import { Button } from '../../../libs/components/button'
import { CheckboxField } from '../../../libs/components/checkbox'
import { Divider } from '../../../libs/components/divider'
import { Fieldset, Label } from '../../../libs/components/fieldset'
import { Input } from '../../../libs/components/input'
import { Loader } from '../../../libs/components/loader'
import { DocumentType } from '../../../libs/components/models/documentTypeMap'
import { PageTitle } from '../../../libs/components/page-title'
import { SidebarItem } from '../../../libs/components/sidebar'
import { Textarea } from '../../../libs/components/textarea'
import Tooltip from '../../../libs/components/tooltip'
import { auth } from '../../../libs/firebase/config'
import { FetchShipmentsById } from '../../../libs/firebase/model'
import useShipmentDetails from '../../../libs/hooks/useShipmentDetails'
import {
  overrideShipmentValidation,
  resetToaster,
} from '../../../libs/reduxStore/slices/shipmentDetails/shipmentDetailsSlice'
import { toggleSidebar } from '../../../libs/reduxStore/slices/sidebar/sidebarSlice'
import { AppDispatch } from '../../../libs/reduxStore/store'
import {
  orgId,
  rightSidePanelFormEnableStatus,
  RouteFieldNames,
  routesKeys,
  ShipmentDetailsState,
  Validation,
} from './model'

const ShipmentView = () => {
  const urlParams = new URLSearchParams(`?${window.location.href?.split('?')[1]}`)
  const isExpanded = urlParams.get('summary-view')
  const [width, setWidth] = useState(35)
  const [rightSidePanel, setRightSidePanel] = useState({
    variant: isExpanded ? 'summary' : '',
    isExpanded: Boolean(isExpanded),
    disabledTransition: false,
  })
  const [shipmentDetails, setShipmentDetails] = useState<ShipmentDetailsState>({
    involvedParties: [
      {
        partyName: 'Shipper',
        name: '',
        email: '',
        nameKey: 'shipper',
        emailKey: 'shipper',
      },
      {
        partyName: 'Consignee',
        name: '',
        email: '',
        nameKey: 'consignee',
        emailKey: 'consignee',
      },
      {
        partyName: 'Notify party',
        name: '',
        email: '',
        nameKey: 'notify_party',
        emailKey: 'notify_party',
      },
    ],
    routes: [],
    containers: [],
  })
  const [validationSummary, setValidationSummary] = useState([
    {
      title: '# Fields require validation',
      value: 0,
      color: 'zinc',
      className: 'text-sm font-semibold',
    },
    {
      title: 'Validated',
      value: 0,
      color: 'zinc',
      className: 'ml-10 text-sm font-semibold',
    },
    {
      title: 'Passed',
      value: 0,
      color: 'green',
      className: 'ml-20 text-sm font-semibold',
    },
    {
      title: 'Failed',
      value: 0,
      color: 'red',
      className: 'ml-20 text-sm font-semibold',
    },
    {
      title: 'Resolved',
      value: 0,
      color: 'green',
      className: 'ml-20 text-sm font-semibold',
    },
    {
      title: 'Pending',
      value: 0,
      color: 'zinc',
      className: 'ml-10 text-sm font-semibold',
    },
  ])
  const [selectedFieldValidation, setSelectedFieldValidation] = useState<Validation | null>(null)
  const [correctInputData, setCorrectInputData] = useState({
    value: '',
    disabled: true,
  })
  const rightSidePanelRef = useRef<HTMLDivElement>(null)
  const { shipmentId } = useParams()
  const [shipmetDetailsRequest] = useState<FetchShipmentsById>({
    orgId,
    shipmentId: shipmentId as string,
    refetch: 0,
  })
  useShipmentDetails(shipmetDetailsRequest)
  const shipment = useSelector((state: any) => state.shipmentDetailsReducer)

  useEffect(() => {
    setWidth(rightSidePanel.isExpanded ? 400 : 35)
  }, [rightSidePanel.isExpanded])

  const dispatch = useDispatch<AppDispatch>()
  const updateShipment = async () => {
    // Retrieve JWT to identify the user to the Identity Platform service.
    // Returns the current token if it has not expired. Otherwise, this will
    // refresh the token and return a new one.
    try {
      const token = await auth?.currentUser?.getIdToken()
      const field = shipment.shipment.data[selectedFieldValidation?.field_name as keyof typeof shipment.shipment]
      const data = {
        [selectedFieldValidation?.field_name as string]:
          typeof field == 'object'
            ? {
                ...field,
                value: correctInputData.value,
              }
            : correctInputData.value,
      }
      dispatch(
        overrideShipmentValidation({
          id: shipmentId || '',
          data,
          version: shipment.shipment.version,
          token: token || '',
        })
      )
    } catch (err) {
      console.log(`Error when submitting vote: ${err}`)
      window.alert(`Something went wrong... Please try again!: ${err}`)
    }
  }

  const rightSidePanelTitle = rightSidePanel.variant === 'summary' ? 'summary' : 'details'

  const isLoading = shipment.isLoading

  const rightSidePanelJSX = (
    <div className={`h-full ${rightSidePanel.isExpanded ? 'p-3' : 'p-2'}`}>
      <section className="flex h-[30px] items-center">
        <SidebarItem
          className={`z-10 cursor-pointer data-[hover]:bg-zinc-100 data-[slot=icon]:*:data-[hover]:fill-zinc-100`}
          btnClassName={`${!rightSidePanel.isExpanded ? '!p-0' : ''}`}
          onClick={() => {
            setRightSidePanel((prev) => ({
              variant: prev.isExpanded ? '' : 'summary',
              isExpanded: !prev.isExpanded,
              disabledTransition: false,
            }))
            dispatch(() => resetToaster())
          }}
        >
          <img src="/static/images/menu.svg" alt="right-menu" height={18} width={18} className="cursor-pointer" />
        </SidebarItem>
        {rightSidePanel.isExpanded ? (
          <h3 className="w-full text-center text-xl font-semibold">Validation {rightSidePanelTitle}</h3>
        ) : null}
      </section>
      <Divider className="mt-3" />
      {rightSidePanel.variant === 'summary' ? (
        <section className="mt-5 flex flex-col gap-3">
          {validationSummary?.map((item) => (
            <div className="flex justify-between">
              <p className={item.className}>{item.title}</p>
              <Badge color={item.color as Colors} className="flex w-[100px] justify-center">
                {item.value}
              </Badge>
            </div>
          ))}
        </section>
      ) : (
        rightSidePanelFormEnableStatus.includes(rightSidePanel.variant) && (
          <>
            <section className={`validation-form !h-[calc(100%-100px)] max-h-[100%] overflow-y-auto py-5`}>
              <p className="text-lg font-semibold">
                {RouteFieldNames[selectedFieldValidation?.field_name as keyof typeof RouteFieldNames]}
              </p>
              <div className="mt-2">
                <label className="mt-2">Correct value to use</label>
                <div className="flex items-center">
                  <Input
                    className={`mt-1 w-[75%]`}
                    type="text"
                    disabled={correctInputData?.disabled}
                    value={correctInputData.value}
                    onChange={(e) =>
                      setCorrectInputData((d) => ({
                        ...d,
                        value: e.target.value,
                      }))
                    }
                  />
                  <Fieldset>
                    <CheckboxField className="!gap-x-[4px] pl-3">
                      <input
                        type="checkbox"
                        className="focus:!text-none text-zinc-950 ring-1"
                        checked={!correctInputData?.disabled}
                        onChange={(e) => {
                          setCorrectInputData((prev) => ({
                            ...prev,
                            disabled: !e.target.checked,
                            value:
                              !e.target.checked && !prev.value
                                ? selectedFieldValidation?.field_values[0]?.value || ''
                                : prev.value,
                          }))
                        }}
                        value={correctInputData?.value}
                      />
                      <Label>Override</Label>
                    </CheckboxField>
                  </Fieldset>
                </div>
                <div className="mt-5 grid grid-cols-12 gap-x-6 gap-y-2">
                  <div className="col-span-5 mt-2">
                    <p className="text-sm font-semibold">Documents</p>
                  </div>
                  <div className="col-span-7 mt-2">
                    <p className="text-sm font-semibold">Values</p>
                  </div>
                  {selectedFieldValidation?.field_values?.map((el) => {
                    const handleClick = (val: string) => {
                      if (['discrepancy', 'overridden'].includes(rightSidePanel.variant)) {
                        setCorrectInputData({
                          value: val,
                          disabled: true,
                        })
                      }
                    }

                    const isSelectedValueSame = correctInputData?.value === el.value

                    return (
                      <>
                        <div className="col-span-5 mt-2 rounded-lg p-2 shadow">
                          {el.shipping_document_ids?.map((id) => {
                            const doc = shipment.shipment.shipping_documents.filter((doc: any) => doc.id === id)
                            return (
                              <p className="truncate text-sm">
                                {DocumentType[doc[0]?.type as keyof typeof DocumentType]}
                              </p>
                            )
                          })}
                        </div>
                        <button
                          className={`col-span-7 mt-2 rounded-lg p-2 shadow ${isSelectedValueSame ? 'bg-zinc-950' : ''}`}
                          onClick={() => {
                            handleClick(el.value)
                          }}
                          disabled={!['discrepancy', 'overridden'].includes(rightSidePanel.variant)}
                        >
                          <p className={`text-sm ${isSelectedValueSame ? 'text-white' : ''}`}>{el.value}</p>
                        </button>
                      </>
                    )
                  })}
                </div>
              </div>
            </section>
            <section className="right-0 h-[50px] w-full bg-white py-2">
              <Divider />
              <div className="mt-2 flex items-center justify-between gap-5 bg-white">
                <div>
                  {shipment.overriddenShipmentDetails.response.status ? (
                    <span className="flex items-center gap-x-3">
                      {shipment.overriddenShipmentDetails.response.status === 'success' ? (
                        <CheckCircleIcon height="20px" width="20px" color="#16a34a" />
                      ) : (
                        <XCircleIcon height="20px" width="20px" color="#ef4444" />
                      )}
                      <div>
                        <p className="text-base/6">{shipment.overriddenShipmentDetails.response.message}</p>
                        {/* {!!shipment.overriddenShipmentDetails.response.subMessage ? (
                          <p className="text-base/6">{shipment.overriddenShipmentDetails.response.subMessage}</p>
                        ) : null} */}
                      </div>
                    </span>
                  ) : null}
                </div>
                <div>
                  <Button
                    plain
                    onClick={() => {
                      setRightSidePanel({
                        variant: '',
                        isExpanded: false,
                        disabledTransition: false,
                      })
                      dispatch(() => resetToaster())
                    }}
                    className="mr-2"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!correctInputData.value || shipment.overriddenShipmentDetails.loader}
                    onClick={updateShipment}
                    isLoading={shipment.overriddenShipmentDetails.loader}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </section>
          </>
        )
      )}
    </div>
  )

  useEffect(() => {
    if (shipment.shipment) {
      const shipmentData = { ...shipmentDetails }
      shipmentData.involvedParties?.forEach((data) => {
        data.name = shipment.shipment?.data?.[data?.nameKey]?.name
        data.email = shipment.shipment?.data?.[data?.emailKey]?.emails?.[0]
      })
      const totalFields = { ...validationSummary[0], value: 0 }
      const validated = { ...validationSummary[1], value: 0 }
      const passed = { ...validationSummary[2], value: 0 }
      const failed = { ...validationSummary[3], value: 0 }
      const resolved = { ...validationSummary[4], value: 0 }
      const pending = { ...validationSummary[5], value: 0 }
      const routesList = routesKeys?.map(({ key, accesorKey, isTimestamp, appendKey, fallBackValidationKey }: any) => {
        const validation =
          shipment.shipment?.validation_result?.records?.find(
            (record: any) => record.field_name === key || record.field_name === fallBackValidationKey
          ) || null

        const nestedObj = !!appendKey
          ? `${shipment.shipment?.data?.[key]?.[accesorKey]} ${shipment.shipment?.data?.[key]?.[appendKey]}`
          : shipment.shipment?.data?.[key]?.[accesorKey]

        const objData = isTimestamp ? shipment.shipment?.data?.[key]?.toDate()?.toISOString() : nestedObj

        const value = typeof shipment.shipment?.data?.[key] == 'object' ? objData : shipment.shipment?.data?.[key]

        if (validation) {
          totalFields.value++
          const { status } = validation
          if (status === 'success') passed.value++
          else if (status === 'discrepancy') failed.value++
          else if (status === 'unvalidated') pending.value++
          else if (status === 'overridden') resolved.value++
        }

        return {
          fieldName: RouteFieldNames[key as keyof typeof RouteFieldNames],
          value,
          validation,
        }
      })

      const containers = shipment?.shipment?.data?.ocean_containers?.map((container: any) => ({
        containerNumber: container.container_number,
        type: container.container_type,
        cargoDescription: container.items?.[0]?.item_description,
      }))

      validated.value = passed.value + failed.value + resolved.value
      setValidationSummary([totalFields, validated, passed, failed, resolved, pending])
      shipmentData.routes = routesList
      shipmentData.containers = containers
      setShipmentDetails(shipmentData)
    }
  }, [shipment.shipment])

  useEffect(() => {
    dispatch(toggleSidebar({ isExpanded: false }))
  }, [])

  const Icons = ({ validation }: { validation: Validation }) => {
    const tooltip = {
      unvalidated: 'Pending validation',
      success: 'Passed validation',
      discrepancy: 'Failed validation',
      overridden: 'Validation manually resolved',
    }

    const status = validation.status
    const openDrawer = () => {
      setRightSidePanel((prevState) => ({
        ...prevState,
        variant: status,
        isExpanded: true,
      }))
      setSelectedFieldValidation(validation)
      dispatch(resetToaster())
      const data = shipment?.shipment?.data[validation?.field_name as string]
      setCorrectInputData((prevData) => ({
        disabled: true,
        value:
          validation.status !== 'discrepancy'
            ? validation.status !== 'overridden'
              ? validation?.field_values[0]?.value
              : typeof data !== 'object'
                ? data
                : data.value
            : '',
      }))
    }

    const props = { height: '20px', width: '20px', className: 'cursor-pointer', onClick: openDrawer }
    return (
      <Tooltip
        tooltip={tooltip[validation.status as keyof typeof tooltip]}
        target={
          status === 'unvalidated' ? (
            <ClockIcon {...props} color="#fde047" />
          ) : status === 'success' ? (
            <CheckCircleIcon {...props} color="#16a34a" />
          ) : status === 'discrepancy' ? (
            <XCircleIcon {...props} color="#ef4444" />
          ) : status === 'overridden' ? (
            <CheckBadgeIcon {...props} color="#16a34a" />
          ) : null
        }
      />
    )
  }

  return (
    <>
      <div className={`-mb-10 flex h-[95vh] max-h-[95vh] overflow-hidden`}>
        <div className={`w-full pr-2 ${!isLoading ? 'max-h-[95vh] overflow-hidden overflow-y-auto' : 'h-full'}`}>
          <Breadcrumb
            pages={[
              {
                name: 'Shipments',
                href: '/shipments',
                current: false,
              },
              {
                name: 'Shipment details',
                href: '#',
                current: true,
              },
            ]}
          />
          <PageTitle title={`Shipment #${shipment?.shipment?.client_shipment_id || ''}`} containerClassName="my-3" />
          {shipment.isLoading ? (
            <Loader />
          ) : (
            <Accordion transition transitionTimeout={200} allowMultiple>
              <AccordionItem header="Involved Parties" initialEntered>
                <div className="flex flex-wrap gap-x-6 gap-y-8">
                  {shipmentDetails.involvedParties?.map(({ partyName, name, email }) => {
                    return (
                      <div className="mt-2 basis-[220px] rounded-lg px-2 py-4 shadow">
                        <p className="text-base font-bold">{partyName}</p>
                        <div className="mt-3">
                          <div>
                            <label className="text-sm">Name</label>
                            <Input type="text" value={name} disabled className="my-2" />
                          </div>
                          <div>
                            <label className="text-sm">Email </label>
                            <Input type="text" value={email} disabled className="my-2" />
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </AccordionItem>
              <AccordionItem header="Shipment data" initialEntered>
                <div className="flex flex-wrap gap-x-6 gap-y-8 px-2 py-4">
                  {shipmentDetails.routes?.map((route) => {
                    return (
                      <div className="basis-[220px]">
                        <div className="relative flex w-[100%] items-center">
                          <label className={`text-sm`}>{route.fieldName}</label>
                          {route.validation ? (
                            <span className="ml-2">
                              <Icons validation={route.validation} />
                            </span>
                          ) : null}
                        </div>
                        <Input type="text" value={route.value} disabled className="my-2" />
                      </div>
                    )
                  })}
                </div>
              </AccordionItem>
              <AccordionItem header="Containers" initialEntered>
                {shipmentDetails?.containers?.map((container: any, index) => (
                  <div>
                    <p className="text-base font-bold">Container #{index + 1}</p>
                    <div className="flex flex-wrap gap-x-6 gap-y-8 px-2 py-4">
                      <div className="basis-[220px]">
                        <div className="flex items-center justify-between">
                          <label className="text-sm">Type</label>
                        </div>
                        <Input type="text" value={container.type} disabled className="my-2" />
                      </div>
                      <div className="basis-[220px]">
                        <div className="flex items-center justify-between">
                          <label className="text-sm">Container #</label>
                        </div>
                        <Input type="text" value={container.containerNumber} disabled className="my-2" />
                      </div>
                      <div className="basis-[300px]">
                        <div className="flex items-center justify-between">
                          <label className="text-sm">Item description</label>
                        </div>
                        <Textarea rows={3} value={container.cargoDescription} disabled className="my-2" />
                      </div>
                    </div>
                    {index + 1 !== shipmentDetails?.containers?.length ? <Divider /> : null}
                  </div>
                ))}
              </AccordionItem>
            </Accordion>
          )}
        </div>
        <div className={`relative isolate flex bg-white max-lg:flex-col w-[${width}px]`} style={{ transition: '0.3s' }}>
          <div
            className={`border-left[1px] border-col border-left-zinc-950 botton-1 relative inset-y-0 right-0 box-content w-full border-[0.5px] border-t-[0.5px] bg-white max-lg:hidden`}
            ref={rightSidePanelRef}
          >
            {/* {rightSidePanel.isExpanded && rightSidePanelRef ? (
              <ResizableBox
                width={width}
                height={rightSidePanelRef.current?.clientHeight || 0}
                style={{ transition: '0.3s' }}
                resizeHandles={['w']}
                onResize={(_e, { size }) => {
                  setWidth(size.width)
                }}
                lockAspectRatio={true}
                maxConstraints={[900, rightSidePanelRef.current?.clientHeight || 0]}
                minConstraints={[403, rightSidePanelRef.current?.clientHeight || 0]}
              >
                {rightSidePanelJSX}
              </ResizableBox>
            ) : (
              rightSidePanelJSX
            )} */}
            <ResizableBox
              width={width}
              height={rightSidePanelRef.current?.clientHeight || 0}
              style={!rightSidePanel.disabledTransition ? { transition: '0.3s' } : {}}
              onResizeStart={() => {
                setRightSidePanel((prev) => ({
                  ...prev,
                  disabledTransition: true,
                }))
              }}
              resizeHandles={['w']}
              onResize={(_e, { size }) => {
                setWidth(size.width)
              }}
              lockAspectRatio={true}
              maxConstraints={[rightSidePanel.isExpanded ? 900 : width, rightSidePanelRef.current?.clientHeight || 0]}
              minConstraints={[rightSidePanel.isExpanded ? 400 : width, rightSidePanelRef.current?.clientHeight || 0]}
            >
              {rightSidePanelJSX}
            </ResizableBox>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShipmentView
